<template>
  <!-- Container fluid  -->
  <div class="container-fluid">
      <!-- Start Page Content -->
      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div id="report" class="card-body">
                      <div class="col-12">
                        <div class="row" style="display:flex; justify-content: center; align-items: center;">
                          <div class="col-4" style="height:5cm; display:flex; justify-content: center; align-items: center; border-style:solid;border-width:1px 1px 1px 1px;padding:15px 15px 15px 15px;">
                            <img v-bind:src="$store.state.logo" style="height: 5cm; max-width:100%; max-height:100%;"/>
                          </div>
                          <div class="col-4" style="height:5cm; display:flex; justify-content: center; align-items: center; border-style:solid;border-width:1px 1px 1px 0px;padding:15px 15px 15px 15px;">
                            <h1 class="text-center">{{ companydata.cmp_nmbre }}</h1>
                          </div>
                          <div class="col-4" style="height:5cm; display:flex; justify-content: center; align-items: center; border-style:solid;border-width:1px 1px 1px 0px;padding:15px 15px 15px 15px;">
                            <div class="col-12">
                              <!--
                              <div class="row border"><span><b>Proceso:</b> GESTIÓN DOCUMENTAL</span></div>
                              <div class="row border"><span><b>Código:</b> GD-F-05</span></div>
                              <div class="row border"><span><b>Versión:</b> 2.0</span></div>
                              <div class="row border"><span><b>Fecha Revisión:</b> Septiembre 30 de 2021</span></div>
                              <div class="row border"><span><b>Fecha Emisión:</b> Septiembre 30 de 2021</span></div>
                              -->
                            </div>
                          </div>
                        </div>
                        <div class="row" style="text-align:center;">
                          <div
                            class="col-12 subtitle"
                            style="border-style:solid;border-width:0px 1px 1px 1px;padding:20px 20px 20px 20px;"
                          >
                            Formato Único de Inventario Documental FUID                            
                          </div>
                        </div>
                        <div class="row" style="text-align:center;">
                          <div
                            class="col-6"
                            style="border-style:solid;border-width:0px 1px 1px 1px;padding:15px 15px 15px 15px;"
                          >Sala: {{ sal_nmbre }}</div>
                          <div
                            class="col-6"
                            style="border-style:solid;border-width:0px 1px 1px 0px;padding:15px 15px 15px 15px;"
                          >Fecha: {{ fecha }}</div>
                        </div>
                      </div>                      
                      <br>
                      <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                          <table id="reporteFuid" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                              <thead>
                                  <tr>
                                      <th>Número</th>
                                      <th>Código Serie</th>
                                      <th>Serie</th>
                                      <th>Nombre del expediente</th>
                                      <th>Fecha inicio</th>
                                      <th>Fecha fin</th>
                                      <th>Unidad conservación</th>
                                      <th># Folios</th>
                                      <th>Soporte</th>
                                      <th>Frecuencia de consulta</th>
                                      <th>Notas</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(item, index) in items" class="rowstyle">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.ser_cdgo }}</td>
                                    <td>{{ item.ser_nmbre }}</td>
                                    <td>{{ item.dcm_asunto }}</td>
                                    <td>{{ item.dcm_fchaini | formatDateUtc }}</td>
                                    <td>{{ item.dcm_fchafin | formatDateUtc }}</td>
                                    <td>{{ item.uco_nmbre }}</td>
                                    <td>{{ item.dcm_nflios }}</td>
                                    <td>{{ item.dcm_soportes }}</td>
                                    <td>{{ item.frecuencia_consulta }}</td>
                                    <td>{{ item.notas }}</td>
                                  </tr>
                              </tbody>                            
                          </table>
                      </div>
                      <br>
                      <div v-if="showProgress" style="text-align:center">
                        <v-progress-circular
                          :size="50"
                          :width="5"
                          color="blue"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                  </div>
                  <br>
                  <div class="row justify-content-around" align="center">
                    <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
                    <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
                  </div>
              </div>
          </div>
      </div>
      <!-- End PAge Content -->
  </div>
  <!-- End Container fluid  -->
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDateUtc } from './../../plugins/filters';
import print from 'print-js';
import XLSX from 'xlsx';

export default {
  components: { 
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      series: [],
      indices: [],
      uconservs: [],
      debug: null,
      fecha: null,
      sal_nmbre: '',
      showProgress: false,
    }
  },
  filters: {
    formatDateUtc
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: async function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    await this.fetchSerie();
    await this.fetchIndice();
    await this.fetchUconserv();
    this.debug = this.$route.params;
    this.sal_nmbre = this.$route.params.sal_nmbre;
    this.searchItems(this.$route.params.query);
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchUconserv(){
      let uri = '/uconservs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.uconservs = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.uconservs.length; j++){
            if (this.items[i].legajo.uco_id == this.uconservs[j]._id){
              this.items[i].uco_nmbre = this.uconservs[j].uco_nmbre;
              j = this.uconservs.length;
            }
          }
        }
        /*
        let m = response.data.index;
        this.files[m]['uploaded'] = '0';
        this.$set(this.files, m, this.files[m]);
        */
      });
    },
    fetchSerie()
    {
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.series = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.series.length; j++){
            if (this.items[i].ser_id == this.series[j]._id){
              this.items[i].ser_cdgo = this.series[j].ser_cdgo;
              this.items[i].ser_nmbre = this.series[j].ser_nmbre;
              j = this.series.length;
            }
          }
        }

      });
    },
    fetchIndice()
    {
      let uri = '/indices/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.indices = response.data;
      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/fuid';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;

          for (let i = 0; i < list.length; i++){
            for (let j = 0; j < this.series.length; j++){
              if (list[i].ser_id == this.series[j]._id){
                list[i].ser_cdgo = this.series[j].ser_cdgo;
                list[i].ser_nmbre = this.series[j].ser_nmbre;
                j = this.series.length;
              }
            }

            for (let j = 0; j < this.uconservs.length; j++){
              if (list[i].legajo.uco_id == this.uconservs[j]._id){
                list[i].uco_nmbre = this.uconservs[j].uco_nmbre;
                j = this.uconservs.length;
              }
            }

            let indices = list[i]?.indices;

            for (let j = 0; j < indices?.length; j++) {
              let f = this.indices.find(t => t._id == indices[j].ind_id && t.ind_nmbre.toLowerCase() == 'frecuencia de consulta');
              if (f) {
                list[i].frecuencia_consulta = indices[j].ixd_valor;
              }
            }
            for (let j = 0; j < indices?.length; j++) {
              let f = this.indices.find(t => t._id == indices[j].ind_id && t.ind_nmbre.toLowerCase() == 'notas');
              if (f) {
                list[i].notas = indices[j].ixd_valor;
              }
            }
          }

          this.items = list;

          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "<link rel='stylesheet' href='../../css/caja.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport(){
      var tableId = 'reporteFuid';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.numero = i + 1;
        item.ser_cdgo = this.items[i].ser_cdgo;
        item.ser_nmbre = this.items[i].ser_nmbre;
        item.dcm_asunto = this.items[i].dcm_asunto;
        item.dcm_fchaini = moment.utc(this.items[i].dcm_fchaini).format('DD/MMM/YYYY');
        if (this.items[i].dcm_fchafin != null){
          item.dcm_fchafin = moment.utc(this.items[i].dcm_fchafin).format('DD/MMM/YYYY');
        } else {
          item.dcm_fchafin = "";
        }
        item.uco_nmbre = this.items[i].uco_nmbre;
        item.dcm_nflios = this.items[i].dcm_nflios;
        item.dcm_soportes = this.items[i].dcm_soportes;
        item.frecuencia_consulta = this.items[i].frecuencia_consulta;
        item.notas = this.items[i].notas;
        
        report.push(item);
      }

      var headersT = ["Número", "Código Serie", "Serie", "Nombre del expediente", "Fecha inicio", "Fecha fin", "Unidad conservación", "# Folios", "Soporte", "Frecuencia de consulta", "Notas"];        

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Formato Único de Inventario Documental FUID"],
        ["Sala: " + this.sal_nmbre],
        [],
        headersT
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>
  @import '../../../public/css/report.css';
  @import "../../../public/css/caja.css";
</style>
